import { addSubRoutes } from "@/router/helper";
/**
 * 理赔---审核管理路由
 */

/**
 * 工程机械 理赔案件
 */
export const claimManagement = {
  path: "/claimManagement",
  name: "claimManagement",
  component: () => import("@/views/claimManagement/index"),
  meta: {
    label: "工程机械-理赔列表",
    permId: "M21",
    name: "claimManagement",
    policyType: 1,
    orderType: 2,
    policyRoute: "ConstructionMachinery",
    icon: require("@/assets/images/claimCase.png"),
  },
};

/**
 * 车险 理赔案件
 */
export const CarInsuranceClaimManagement = {
  path: "/claimManagement2",
  name: "claimManagement2",
  component: () => import("@/views/claimManagement/index2"),
  meta: {
    label: "车险-理赔案件",
    permId: "M21-carInsurance",
    name: "claimManagement2",
    policyType: 2,
    orderType: 2,
    policyRoute: "CarInsurance",
    icon: require("@/assets/images/claimCase.png"),
  },
};

/**
 * 托管保单 理赔案件
 */
export const carClaimManagement = {
  path: "/claimManagement3",
  name: "claimManagement3",
  component: () => import("@/views/claimManagement/index3"),
  meta: {
    label: "托管保单-理赔案件",
    permId: "M21-hosting",
    name: "claimManagement3",
    policyType: 1,
    orderType: 8,
    icon: require("@/assets/images/claimCase.png"),
  },
};

/**
 * 理赔案件 非公司
 */
export const nonClaimManagement = {
  path: "/nonClaimManagement",
  name: "nonClaimManagement",
  component: () => import("@/views/claimManagement/nonIndex"),
  meta: {
    label: "非公司-理赔案件",
    permId: "M21-View-21",
    icon: require("@/assets/images/claimCase.png"),
  },
};

/**
 * 验标审核
 */
export const auditManagement = {
  path: "/auditManagement",
  name: "auditManagement",
  component: () => import("@/views/AuditManagement/index"),
  meta: {
    label: "理赔审核",
    permId: "M27",
    icon: require("@/assets/images/claimAudit.png"),
  },
};
//理赔详情与修改
export const claimDetails = {
  path: "/claimDetails/:id/:steps?",
  name: "claimDetails",
  component: () => import("@/views/claimManagement/claimDetails.vue"),
  meta: {
    label: "理赔详情",
    permId: "M21-details",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};

//理赔详情与修改 - 非公司
export const nonClaimDetails = {
  path: "/nonClaimDetails/:id/:steps?",
  name: "nonClaimDetails",
  component: () => import("@/views/claimManagement/nonClaimDetails.vue"),
  meta: {
    label: "理赔详情",
    permId: "M21-View-21-detail",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};

export const applyClaim = {
  path: "/applyClaim/:id?",
  name: "applyClaim",
  component: () => import("@/views/policyManagement/applyClaim.vue"),
  meta: {
    label: "申请理赔",
    permId: "M21",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};

// 添加报案/编辑报案信息  非公司
export const applyNonClaim = {
  path: "/applyNonClaim/:id?",
  name: "applyNonClaim",
  component: () => import("@/views/claimManagement/applyNonClaim.vue"),
  meta: {
    label: "申请理赔",
    permId: "M21-View-21-edit",
    icon: "icon-anquanguanli2",
    hideMenu: true,
  },
};
// /**
//  * 定损审核
//  */
// export const FixedAudit = {
//   path: '/FixedAudit',
//   name: 'FixedAudit',
//   component: () => import('@/views/AuditManagement/FixedAudit'),
//   meta: {
//     label: '定损审核',
//     permId: 'M27-fixed',
//     icon: 'icon-a-yonghuguanli1',
//   }
// };

// /**
//  * 理算审核
//  */
// export const SettlementAudit = {
//   path: '/SettlementAudit',
//   name: 'SettlementAudit',
//   component: () => import('@/views/AuditManagement/SettlementAudit'),
//   meta: {
//     label: '理算审核',
//     permId: 'M27-settlement',
//     icon: 'icon-a-yonghuguanli1',
//   },
// };

// /**
//  * 重开案件审核
//  */
// export const ReopenAudit = {
//   path: '/ReopenAudit',
//   name: 'ReopenAudit',
//   component: () => import('@/views/AuditManagement/ReopenAudit'),
//   meta: {
//     label: '重开案件审核',
//     permId: 'M27-reopen',
//     icon: 'icon-a-yonghuguanli1',
//   }
// };

/**
 * 理赔审核员维护
 */
export const claimAuditorManagement = {
  path: "/claimAuditorManagement",
  name: "claimAuditorManagement",
  component: () => import("@/views/claimAuditorManagement/index.vue"),
  meta: {
    label: "审核员维护",
    permId: "M28",
    icon: require("@/assets/images/claimCase.png"),
  },
};

//新能源理赔index
export const newEnergyClaimManagement = {
  path: "/newEnergyClaimManagement",
  name: "newEnergyClaimManagement",
  component: () => import("@/views/newEnergyClaim"),
  meta: {
    label: "新能源理赔",
    permId: "M21NewEng",
    icon: require("@/assets/images/safeDuty/menu/交通安责.png"),
    redirect: true,
  },
};
